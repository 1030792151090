import baseURL from "@/utils/config";
import request from "@/plugins/axios"
//优惠卷
export function getFindCanGetCoupon(data){
  return request({
      url:`${baseURL.api}/api/product/findCanGetCoupon`,
      method: "post",
      data,
  });
}
//领取优惠卷
export function getTakeCanGetCoupon(data){
  return request({
      url:`${baseURL.api}/authapi/user/takeCoupon`,
      method: "post",
      data,
    
  });
}
//我的优惠卷
export function getSearchUserCoupon(data){
  return request({
      url:`${baseURL.api}/authapi/user/searchUserCoupon`,
      method: "post",
      data
  });
}